import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class CommonSettingsApi {
  private readonly requestUrl: string = '/api/CommonSettings';

  constructor(private http: HttpClient) { }

  getCommonSettings(): Observable<CommonSettings[]> {
    return this.http.get<CommonSettings[]>(`${this.requestUrl}`);
  }

  getCommonSetting(key: string): Observable<CommonSettings> {
    return this.http.get<CommonSettings>(`${this.requestUrl}/${key}`);
  }

  postCommonSettings(c: CommonSettings): Observable<void> {
    return this.http.post<void>(`${this.requestUrl}`, c);
  }
}

export interface CommonSettings {
  key: string;
  value: string;
}


