import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { SettingsChooserComponent } from './settings/settings-chooser/settings-chooser.component';
import { CustomersComponent } from './settings/customers/customers.component';
import { DeactivateGuard } from './settings/settings-chooser/deactivate.guard';
import { CustomersApi } from './settings/customers/customers-api';
import { CreateCustomerComponent } from './settings/create-customer/create-customer.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddDefaultTypeComponent } from './commons/add-default-type/add-default-type.component';
import { GlobalWhitelistComponent } from './settings/global-whitelist/global-whitelist.component';
import { GlobalWhitelistApi } from './settings/global-whitelist/global-whitelist-api';
import { UpsertEmailComponent } from './settings/global-whitelist/upsert-email/upsert-email.component';
import { DeleteEmailComponent } from './settings/global-whitelist/delete-email/delete-email.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlantsComponent } from './settings/plants/plants.component';
import { PlantsApi } from './settings/plants/plants-api';
import { UpsertPlantComponent } from './settings/plants/upsert-plant/upsert-plant.component';
import { DeletePlantComponent } from './settings/plants/delete-plant/delete-plant.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CriptokeysComponent } from './settings/cryptokeys/cryptokeys.component';
import { CryptokeysApi } from './settings/cryptokeys/cryptokeys-api';
import { UpsertCryptokeyComponent } from './settings/cryptokeys/upsert-cryptokey/upsert-cryptokey.component';
import { DeleteCryptokeyComponent } from './settings/cryptokeys/delete-cryptokey/delete-cryptokey.component';
import { BrainyHelperChooserComponent } from './brainy-helper/brainy-helper-chooser/brainy-helper-chooser.component';
import { RulesManagementComponent } from './brainy-helper/rules-management/rules-management.component';
import { AvgsManagementComponent } from './brainy-helper/avgs-management/avgs-management.component';
import { AvgsManagementApi } from './brainy-helper/avgs-management/avgs-management-api';
import { RulesManagementApi } from './brainy-helper/rules-management/rules-management-api';
import { UpsertAvgElementComponent } from './brainy-helper/avgs-management/upsert-avg-element/upsert-avg-element.component';
import { DeleteAvgElementComponent } from './brainy-helper/avgs-management/delete-avg-element/delete-avg-element.component';
import { DeleteRuleComponent } from './brainy-helper/rules-management/delete-rule/delete-rule.component';
import { UpsertRuleComponent } from './brainy-helper/rules-management/upsert-rule/upsert-rule.component';
import { ShipmentDurationComponent } from './brainy-helper/shipment-duration/shipment-duration.component';
import { CommonApi } from './commons/common-api';
import { ShipmentDurationApi } from './brainy-helper/shipment-duration/shipment-duration-api';
import { EditCustomerComponent } from './settings/edit-customer/edit-customer.component';
import { StatsComponent } from './stats/stats.component';
import { SmsComponent } from './stats/sms/sms.component';
import { DocksUsageComponent } from './stats/docks-usage/docks-usage.component';
import { DocksUsageApi } from './stats/docks-usage/docks-usage-api';
import { ApiComponent } from './stats/api/api.component';
import { ConfirmComponent } from './commons/confirm/confirm.component';
import { FileApi } from './commons/file-api';
import { IconsComponent } from './settings/icons/icons.component';
import { CommonSettingsComponent } from './settings/common-settings/common-settings.component';
import { CommonSettingsApi } from './settings/common-settings/common-settings-api';
import { CommonIconsComponent } from './settings/common-icons/common-icons.component';
import { CommonModule } from '@angular/common';
import { AddCommonIconComponent } from './commons/add-common-icon/add-common-icon.component';
import { DeleteCommonIconComponent } from './commons/delete-common-icon/delete-common-icon.component';
import { CustomPrivacyComponent } from './settings/custom-privacy/custom-privacy.component';
import { CustomPrivacyApi } from './settings/custom-privacy/custom-privacy-api';
import { UpsertCustomPrivacyComponent } from './settings/custom-privacy/upsert-custom-privacy/upsert-custom-privacy.component';
import { DeleteCustomPrivacyComponent } from './settings/custom-privacy/delete-custom-privacy/delete-custom-privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    SettingsChooserComponent,
    CustomersComponent,
    CreateCustomerComponent,
    AddDefaultTypeComponent,
    GlobalWhitelistComponent,
    UpsertEmailComponent,
    DeleteEmailComponent,
    PlantsComponent,
    UpsertPlantComponent,
    DeletePlantComponent,
    CriptokeysComponent,
    UpsertCryptokeyComponent,
    DeleteCryptokeyComponent,
    BrainyHelperChooserComponent,
    RulesManagementComponent,
    AvgsManagementComponent,
    UpsertAvgElementComponent,
    DeleteAvgElementComponent,
    DeleteRuleComponent,
    UpsertRuleComponent,
    ShipmentDurationComponent,
    EditCustomerComponent,
    StatsComponent,
    DocksUsageComponent,
    SmsComponent,
    ApiComponent,
    ConfirmComponent,
    IconsComponent,
    CommonSettingsComponent,
    CommonIconsComponent,
    AddCommonIconComponent,
    DeleteCommonIconComponent,
    CustomPrivacyComponent,
    UpsertCustomPrivacyComponent,
    DeleteCustomPrivacyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ApiAuthorizationModule,
    LeafletModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthorizeGuard] },
      {
        path: 'settings', component: SettingsChooserComponent, canActivate: [AuthorizeGuard], children: [
          { path: 'createCustomer', component: CreateCustomerComponent, canDeactivate: [DeactivateGuard] },
          { path: 'editCustomer/:customerId', component: EditCustomerComponent, canDeactivate: [DeactivateGuard] },
          { path: 'customers', component: CustomersComponent, canDeactivate: [DeactivateGuard] },
          { path: 'globalWhitelist', component: GlobalWhitelistComponent, canDeactivate: [DeactivateGuard] },
          { path: 'plants', component: PlantsComponent, canDeactivate: [DeactivateGuard] },
          { path: 'cryptokeys', component: CriptokeysComponent, canDeactivate: [DeactivateGuard] },
          { path: 'icons', component: IconsComponent },
          { path: 'commonSettings', component: CommonSettingsComponent },
          { path: 'commonIcons', component: CommonIconsComponent },
          { path: 'customPrivacy', component: CustomPrivacyComponent },
          { path: '', redirectTo: 'customers', pathMatch: 'full' }
        ]
      },
      {
        path: 'brainyHelper', component: BrainyHelperChooserComponent, canActivate: [AuthorizeGuard], children: [
          { path: 'avgsManagement', component: AvgsManagementComponent, canDeactivate: [DeactivateGuard] },
          { path: 'rulesManagement', component: RulesManagementComponent, canDeactivate: [DeactivateGuard] },
          { path: 'shipmentDuration', component: ShipmentDurationComponent, canDeactivate: [DeactivateGuard] },
          { path: '', redirectTo: 'avgsManagement', pathMatch: 'full' }
        ]
      },
      {
        path: 'stats', component: StatsComponent, canActivate: [AuthorizeGuard], children: [
          { path: 'sms', component: SmsComponent },
          { path: 'docksUsage', component: DocksUsageComponent },
          { path: 'api', component: ApiComponent },
          { path: '', redirectTo: 'sms', pathMatch: 'full' }
        ]
      }
    ])
  ],
  providers: [
    CustomersApi,
    CommonSettingsApi,
    GlobalWhitelistApi,
    PlantsApi,
    CryptokeysApi,
    CustomPrivacyApi,
    AvgsManagementApi,
    RulesManagementApi,
    CommonApi,
    ShipmentDurationApi,
    DocksUsageApi,
    FileApi,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
