import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { CodeName } from "../../commons/generic-helper";

@Injectable()
export class PlantsApi {
  private readonly requestUrl: string = '/api/plants';

  constructor(private http: HttpClient) { }

  getPlants(customerId: number): Observable<Plant[]> {
    return this.http.get<Plant[]>(`${this.requestUrl}/GetPlants/${customerId}`);
  }

  upsert(customerId: number, plant: Plant): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}/${customerId}`, plant);
  }

  delete(customerId: number, plant: Plant): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${plant.id}/${customerId}`);
  }

}

export interface Plant {
  address: string;
  cap: string;
  city: string;
  state: string;
  description: string;
  externalCode: string;
  lat: number;
  lng: number;
  id: number;
}
