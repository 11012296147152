import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { CodeName } from "../../commons/generic-helper";

@Injectable()
export class RulesManagementApi {
  private readonly requestUrl: string = '/api/RulesManagement';

  constructor(private http: HttpClient) { }

  get(customerId: number, plantCode: string): Observable<Rule[]> {
    return this.http.get<Rule[]>(`${this.requestUrl}/${customerId}/${plantCode}`);
  }

  upsert(rule: Rule): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, rule);
  }

  delete(rule: Rule): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${rule.id}`);
  }

  getPlantOptions(customerId: number, plantCode: string): Observable<PlantOptions> {
    return this.http.get<PlantOptions>(`${this.requestUrl}/GetPlantOptions/${customerId}/${plantCode}`);
  }
}

export interface Rule {
  id: number;
  customerId: number;
  plantId: number;
  plantCode: string;
  truckType: string;
  destinationCountry: string;
  deliveryMethod: string;
  packageType: string;
  action: Action;
  minutes: number;
  minutesToUpdate: number;
  rollingDays: number;
  frequency: number;
  magicNumber: number;
  overwrite: boolean;
  lastRun: Date;
  fixedTime: boolean;
}
export enum Action {
  None = 0,
  Load = 1,
  Unload = 2,
  LoadUnload = 3
}

export interface PlantOptions {
  destinations: CodeName[];
  truckTypes: CodeName[];
  products: CodeName[];
  tripTypes: CodeName[];
}
