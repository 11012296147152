import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCommonIconComponent } from '../../commons/add-common-icon/add-common-icon.component';
import { Icon, TypeEnum } from '../../commons/default-types-values';
import { DeleteCommonIconComponent } from '../../commons/delete-common-icon/delete-common-icon.component';
import { IconApi } from '../icons/icon-api';

@Component({
  selector: 'app-common-icons',
  templateUrl: './common-icons.component.html',
  styleUrls: ['./common-icons.component.scss']
})
export class CommonIconsComponent {

  handlingUnitIcons: Icon[] = [];
  truckTypeIcons: Icon[] = [];
  tripTypeIcons: Icon[] = [];

  constructor(private iconApi: IconApi, private modal: NgbModal) {
    this.updateList();
  }

  updateList() {
    this.iconApi.getCommonIcons().subscribe(x => {
      if (x && x.length > 0) {
        this.handlingUnitIcons = x[0].handlingUnitIcons;
        this.truckTypeIcons = x[0].truckTypeIcons;
        this.tripTypeIcons = x[0].tripTypesIcons;
      }
    })
  }

  async addCommonIcon(type: number) {
    let ref = await this.modal.open(AddCommonIconComponent);
    if (type == 0) {
      ref.componentInstance.type = TypeEnum.HandlingUnit;
    }
    if (type == 1) {
      ref.componentInstance.type = TypeEnum.TruckType;
    }
    if (type == 2) {
      ref.componentInstance.type = TypeEnum.TripType;
    }

    try {
      await ref.result;
      this.updateList();
      return true;
    } catch { return false; }
  }


  async deleteCommonIcon(code: string, type: number) {
    let ref = await this.modal.open(DeleteCommonIconComponent);
    ref.componentInstance.code = code;
    ref.componentInstance.type = type;
    try {
      await ref.result;
      this.updateList();
      return true;
    } catch { return false; }
  }

}
