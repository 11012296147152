import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { CustomerIcon } from "../../commons/default-types-values";

@Injectable({
  providedIn:'root'
})
export class IconApi {
  private readonly requestUrl: string = '/api/Icons';

  constructor(private http: HttpClient) { }

  get(): Observable<CustomerIcon[]> {
    return this.http.get<CustomerIcon[]>(`${this.requestUrl}`);
  }

  getCommonIcons(): Observable<CustomerIcon[]> {
    return this.http.get<CustomerIcon[]>(`${this.requestUrl}/GetCommonIcons`);
  }

  deleteCommonIcon(code: string, type: number): Observable<number> {
    return this.http.get<number>(`${this.requestUrl}/DeleteCommonIcon/${code}/${type}`);
  }
  
}

