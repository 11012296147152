import { Component, OnInit, OnDestroy } from '@angular/core';
import { CodeName } from '../../commons/generic-helper';
import { CommonApi, PlantTypes } from '../../commons/common-api';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { BrainyHelperChooserComponent } from '../brainy-helper-chooser/brainy-helper-chooser.component';
import { tap, takeUntil, switchMap, map, filter } from 'rxjs/operators';
import { Package, ShipmentParameters, ShipmentDurationApi, Duration } from './shipment-duration-api';

@Component({
  selector: 'app-shipment-duration',
  templateUrl: './shipment-duration.component.html',
  styleUrls: ['./shipment-duration.component.scss']
})
export class ShipmentDurationComponent implements OnDestroy {

  unsubscribe = new Subject<void>();

  customerList: CodeName[];
  plantList: CodeName[];
  plantTypes: PlantTypes;

  customer = new FormControl(null, [Validators.required]);
  plant = new FormControl(null, [Validators.required]);
  truckType = new FormControl(null, [Validators.required]);
  packages = new BehaviorSubject<Package[]>(null);

  result = new BehaviorSubject<Duration>(null);

  constructor(private durationApi: ShipmentDurationApi, private commonApi: CommonApi, private readonly parent: BrainyHelperChooserComponent) {
    this.commonApi.getCustomerList().subscribe(x => this.customerList = x);

    this.customer.valueChanges
      .pipe(
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        switchMap((customerId: number) => this.commonApi.getCustomerPlantList(customerId))
      )
      .subscribe(x => {
        this.plant.reset();
        this.truckType.reset();
        this.packages.next(null);
        this.plantList = x;
        this.parent.hideProgressbar();
      });

    this.plant.valueChanges
      .pipe(
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        filter(x => x),
        map((x: string) => x.split("-")[0]),
        switchMap((plantCode: string) => this.commonApi.getPlantTypes(this.customer.value, plantCode))
      )
      .subscribe(x => {
        this.truckType.reset();
        this.packages.next(null);
        this.plantTypes = x;
        this.parent.hideProgressbar();
      });
  }

  addPackage() {
    let newRow: Package[] = [{
      action: null,
      deliveryMethod: null,
      destinationCountry: null,
      packageType: null,
      quantity: null
    }];

    this.packages.next(this.packages.value ? this.packages.value.concat(newRow) : newRow);
  }

  deletePackage(row: Package) {
    this.packages.next(this.packages.value.filter(x => x != row));
  }

  calculate() {
    let params: ShipmentParameters = {
      customerCode: this.customerList.find(x => x.code == this.customer.value).name,
      plantCode: this.plant.value.split("-")[0],
      truckType: this.truckType.value,
      packages:  this.packages.value.map(x => <Package>{
        packageType: x.packageType,
        destinationCountry: x.destinationCountry,
        deliveryMethod: x.deliveryMethod,
        quantity: x.quantity,
        action: parseInt(x.action.toString()) 
      })
    };

    this.durationApi.getDuration(params).subscribe(x => { this.result.next(x); console.log(x); });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
