import { Component, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-settings-chooser',
  templateUrl: './settings-chooser.component.html',
  styleUrls: ['./settings-chooser.component.scss']
})
export class SettingsChooserComponent implements OnDestroy {
  private readonly Destroyed = new Subject<void>();
  private readonly tsSub: Subscription;
  isLoading: boolean;

  constructor(route: ActivatedRoute, route2: Router) {
    this.tsSub = route2.events.subscribe(() => {
      this.toasts = []; //toglie i toast al cambio di router per evitare confusione
    });
  }
  ngOnDestroy() {
    this.Destroyed.next();
    this.Destroyed.complete();
    this.tsSub.unsubscribe();
  }

  showProgressbar() {
    this.isLoading = true;
  }
  hideProgressbar() {
    this.isLoading = false;
  }

  //#region toasts
  @ViewChild('success')
  public SuccTemplate: TemplateRef<any>;

  @ViewChild('error')
  public ErrTemplate: TemplateRef<any>;

  public toasts: any[] = [];

  public showSuccess() {
    this.show(this.SuccTemplate, { classname: 'bg-success text-light', delay: 10000 });
  }
  public showError() {
    this.show(this.ErrTemplate, { classname: 'bg-danger text-light', delay: 10000 });
  }

  public show(tpl: TemplateRef<any>, options: any = {}) {
    this.toasts.push({ tpl, ...options });
  }
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  //#endregion

}
