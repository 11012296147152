import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-custom-privacy',
  templateUrl: './delete-custom-privacy.component.html',
  styleUrls: ['./delete-custom-privacy.component.scss']
})
export class DeleteCustomPrivacyComponent implements OnInit {

  constructor(public readonly modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
