import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CodeName } from '../../../commons/generic-helper';
import { CustomPrivacy } from '../custom-privacy-api';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upsert-custom-privacy',
  templateUrl: './upsert-custom-privacy.component.html',
  styleUrls: ['./upsert-custom-privacy.component.scss']
})
export class UpsertCustomPrivacyComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  unsubscribe = new Subject<void>();

  customPrivacy: CustomPrivacy = null;
  title: string;
  plantList: CodeName[];

  public formGroup: FormGroup = this.formBuilder.group(
    {
      privacyIt: new FormControl(null),
      privacyEn: new FormControl(null),
      privacyLinkIt: new FormControl(null),
      privacyLinkEn: new FormControl(null),
      cookieIt: new FormControl(null),
      cookieEn: new FormControl(null),
      cookieLinkIt: new FormControl(null),
      cookieLinkEn: new FormControl(null),
    },
    {
      hideRequired: false,
      floatLabel: 'auto'
    }
  );

  constructor(public readonly modal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (!!this.customPrivacy)
      this.formGroup.patchValue({
        privacyIt: this.customPrivacy.privacyIt,
        privacyEn: this.customPrivacy.privacyEn,
        privacyLinkIt: this.customPrivacy.privacyLinkIt,
        privacyLinkEn: this.customPrivacy.privacyLinkEn,
        cookieIt: this.customPrivacy.cookieIt,
        cookieEn: this.customPrivacy.cookieEn,
        cookieLinkIt: this.customPrivacy.cookieLinkIt,
        cookieLinkEn: this.customPrivacy.cookieLinkEn,
      });
  }

  save() {
    let customPrivacy: CustomPrivacy = {
      id: !!this.customPrivacy ? this.customPrivacy.id : 0,
      privacyIt: this.formGroup.controls.privacyIt.value,
      privacyEn: this.formGroup.controls.privacyEn.value,
      privacyLinkIt: this.formGroup.controls.privacyLinkIt.value,
      privacyLinkEn: this.formGroup.controls.privacyLinkEn.value,
      cookieIt: this.formGroup.controls.cookieIt.value,
      cookieEn: this.formGroup.controls.cookieEn.value,
      cookieLinkIt: this.formGroup.controls.cookieLinkIt.value,
      cookieLinkEn: this.formGroup.controls.cookieLinkEn.value,
      identityCustomerId: !!this.customPrivacy ? this.customPrivacy.identityCustomerId : 0,
    };

    this.modal.close(customPrivacy);
  }

}
