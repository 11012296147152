import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class CryptokeysApi {
  private readonly requestUrl: string = '/api/cryptokeys';

  constructor(private http: HttpClient) { }

  get(customerId: number): Observable<Key[]> {
    return this.http.get<Key[]>(`${this.requestUrl}/${customerId}`);
  }

  upsert(customerId, k: Key): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}/${customerId}`, k);
  }

  delete(k: Key): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${k.id}`);
  }

  getKeyList(): Observable<string[]> {
    return this.http.get<string[]>(`${this.requestUrl}/GetKeyList`);
  }
}


export interface Key {
  id: number;
  from: Date;
  to: Date;
  expired: boolean;
  value: string;
  plantCode: string,
  plantDescription: string
}
