import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DefaultTypesValues, Destination, TruckType, TripType, Product, GateType, TypeEnum } from '../../commons/default-types-values';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AddDefaultTypeComponent } from '../../commons/add-default-type/add-default-type.component';
import { Customer, CustomersApi } from '../customers/customers-api';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { SettingsChooserComponent } from '../settings-chooser/settings-chooser.component';
import { IChangeUrl } from '../settings-chooser/deactivate.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit, IChangeUrl {

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false
  };

  name = new FormControl(null, [Validators.required]);
  email = new FormControl(null, [Validators.required, Validators.email]);
  password = new FormControl(null, [Validators.required]);
  passwordConfirm = new FormControl(null, [Validators.required]);

  types = DefaultTypesValues;
  destinations = new FormControl(this.types.destinations.value);
  truckTypes = new FormControl(this.types.truckTypes.value);
  tripTypes = new FormControl(this.types.tripTypes.value);
  products = new FormControl(this.types.products.value);
  gates = new FormControl(this.types.gates.value);

  showAdvanced = false;
  errorMessage: string = '';

  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  unsavedChanges: boolean = false;

  constructor(public router: Router, private readonly parent: SettingsChooserComponent, private api: CustomersApi, private formBuilder: FormBuilder, private modal: NgbModal) {

    this.formGroup.addControl("name", this.name);
    this.formGroup.addControl("email", this.email);
    this.formGroup.addControl("password", this.password);
    this.formGroup.addControl("passwordConfirm", this.passwordConfirm);
    
    this.formGroup.addControl("destinations", this.destinations);
    this.formGroup.addControl("truckTypes", this.truckTypes);
    this.formGroup.addControl("tripTypes", this.tripTypes);
    this.formGroup.addControl("products", this.products);
    this.formGroup.addControl("gates", this.gates);

    this.formGroup.setValidators((group: FormGroup) => {
      let pass: string = group.value.password;
      let confirm: string = group.value.passwordConfirm;

      if (pass == null || pass.length == 0 || confirm == null || confirm.length == 0)
        return null;

      if (pass != confirm)
        return { noMatch: true };

      return null;
    });
    this.formGroup.valueChanges.subscribe(x => this.unsavedChanges = true);
  }

  ngOnInit(): void {
  }

  async addDestination() {
    let usedCodes = this.types.destinations.value.map(x => x.code);
    let toAdd: Destination = { code: null, name: null, used: false };
    let res = await this.addType(usedCodes, toAdd, null, 50, { type: TypeEnum.Destination, customerCode: '' });
    if (res) {
      this.types.destinations.next(this.types.destinations.value.concat([toAdd]));
      let oldSelection = this.destinations.value;
      this.destinations.setValue(oldSelection.concat([toAdd]));
    }
  }

  public async editDestination(elem: Destination) {
    let usedCodes = this.types.destinations.value.map(x => x.code);
    let toEdit: Destination = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 50, { type: TypeEnum.Destination, customerCode: '' });
    if (res) {
      this.destinations.value.splice(this.destinations.value.indexOf(elem), 1);
      this.destinations.value.push(toEdit);
      this.types.destinations.next(this.destinations.value);
    }
  }

  async addGate() {
    let usedCodes = this.types.gates.value.map(x => x.code);
    let toAdd: GateType = { code: null, name: null, used:false };
    let res = await this.addType(usedCodes, toAdd, null, 5, { type: TypeEnum.GateType, customerCode: '' });
    if (res) {
      this.types.gates.next(this.types.gates.value.concat([toAdd]));
      let oldSelection = this.gates.value;
      this.gates.setValue(oldSelection.concat([toAdd]));
    }
  }
  public async editGateType(elem: GateType) {
    let usedCodes = this.types.gates.value.map(x => x.code);
    let toEdit: GateType = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 5, { type: TypeEnum.GateType, customerCode: '' });
    if (res) {
      this.gates.value.splice(this.gates.value.indexOf(elem), 1);
      this.gates.value.push(toEdit);
      this.types.gates.next(this.gates.value);
    }
  }

  async addTruckType() {
    let usedCodes = this.types.truckTypes.value.map(x => x.code);
    let toAdd: TruckType = { code: null, name: null, used: false };
    let res = await this.addType(usedCodes, toAdd, null, 5, { type: TypeEnum.TruckType, customerCode: '' });
    if (res) {
      this.types.truckTypes.next(this.types.truckTypes.value.concat([toAdd]));
      let oldSelection = this.truckTypes.value;
      this.truckTypes.setValue(oldSelection.concat([toAdd]));
    }
  }
  public async editTruckType(elem: GateType) {
    let usedCodes = this.types.truckTypes.value.map(x => x.code);
    let toEdit: TruckType = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 5, { type: TypeEnum.TruckType, customerCode: '' });
    if (res) {
      this.truckTypes.value.splice(this.truckTypes.value.indexOf(elem), 1);
      this.truckTypes.value.push(toEdit);
      this.types.truckTypes.next(this.truckTypes.value);
    }
  }
  async addTripType() {
    let usedCodes = this.types.tripTypes.value.map(x => x.code);
    let toAdd: TripType = { code: null, name: null, used: false };
    let res = await this.addType(usedCodes, toAdd, null, 5, { type: TypeEnum.TripType, customerCode: '' });
    if (res) {
      this.types.tripTypes.next(this.types.tripTypes.value.concat([toAdd]));
      let oldSelection = this.tripTypes.value;
      this.tripTypes.setValue(oldSelection.concat([toAdd]));
    }
  }
  public async editTripType(elem: TripType) {
    let usedCodes = this.types.tripTypes.value.map(x => x.code);
    let toEdit: TripType = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 5, { type: TypeEnum.TripType, customerCode: '' });
    if (res) {
      this.tripTypes.value.splice(this.tripTypes.value.indexOf(elem), 1);
      this.tripTypes.value.push(toEdit);
      this.types.tripTypes.next(this.tripTypes.value);
    }
  }
  async addProduct() {
    let usedCodes = this.types.products.value.map(x => x.code);
    let toAdd: Product = { code: null, name: null, x: 0, weight: 0, y: 0, z: 0, used:false, limit: null };
    let res = await this.addType(usedCodes, toAdd, null, 50, { type: TypeEnum.HandlingUnit, customerCode: '' });
    if (res) {
      this.types.products.next(this.types.products.value.concat([toAdd]));
      let oldSelection = this.products.value;
      this.products.setValue(oldSelection.concat([toAdd]));
    }
  }
  public async editProduct(elem: Product) {
    let usedCodes = this.types.products.value.map(x => x.code);
    let toEdit: Product = { code: elem.code, name: elem.name, x: elem.x, weight: elem.weight, y: elem.y, z: elem.z, used: elem.used, limit: elem.limit };
    let res = await this.addType(usedCodes, toEdit, null, 50, { type: TypeEnum.HandlingUnit, customerCode: '' });
    if (res) {
      this.products.value.splice(this.products.value.indexOf(elem), 1);
      this.products.value.push(toEdit);
      this.types.products.next(this.products.value);
    }
  }
  async addType(usedCodes: string[], newType, referenceCodesList: any = null, maxCodePropertyLength: number = null, iconOptions?: { type: TypeEnum, customerCode: string }) {
    let ref = await this.modal.open(AddDefaultTypeComponent);

    ref.componentInstance.newType = newType;
    ref.componentInstance.usedCodes = usedCodes;
    ref.componentInstance.referenceCodesList = referenceCodesList;
    ref.componentInstance.maxCodePropertyLength = maxCodePropertyLength;
    ref.componentInstance.type = iconOptions.type;
    ref.componentInstance.customerCode = iconOptions.customerCode;

    try {
      await ref.result;
      return true;
    } catch{ return false; }
  }

  createCustomer() {
    var newCustomer: Customer = {
      id: null,
      name: this.name.value,
      email: this.email.value,
      password: this.password.value,
      advancedSettings: {
        destinations: this.destinations.value,
        truckTypes: this.truckTypes.value,
        tripTypes: this.tripTypes.value,
        products: this.products.value,
        gateTypes: this.gates.value
      }
    };

    this.parent.showProgressbar();
    this.api.createCustomer(newCustomer)
      .pipe(
        catchError((x: HttpErrorResponse) => {
          console.error("❌ " + x.error);
          this.parent.showError();
          this.unsavedChanges = false;
          this.errorMessage = x.error;
          this.parent.hideProgressbar();
          return of('KO');
        })
      ).subscribe(x => {
        if (x != 'KO') {
          this.formGroup.reset();
          this.parent.showSuccess();
          this.unsavedChanges = false;
          this.errorMessage = '';
          this.parent.hideProgressbar();
          this.router.navigateByUrl('/settings/customers');
        }
      })
  }

  public changeUrl() {
    return !this.unsavedChanges;
  }

  public remove<T>(elem: T, list: T[]) {
    const idx = list.indexOf(elem);
    if (idx > -1)
      list.splice(idx, 1);
  }
  saveChanges() { this.createCustomer(); }

}
