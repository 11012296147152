import { Component, OnInit } from '@angular/core';
import { CodeName, GenericHelper } from '../../commons/generic-helper';
import { Subject, combineLatest, BehaviorSubject, of } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomPrivacyApi, CustomPrivacy } from './custom-privacy-api';
import { CommonApi } from '../../commons/common-api';
import { SettingsChooserComponent } from '../settings-chooser/settings-chooser.component';
import { takeUntil, tap, switchMap, catchError } from 'rxjs/operators';
import { UpsertCustomPrivacyComponent } from './upsert-custom-privacy/upsert-custom-privacy.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteCustomPrivacyComponent } from './delete-custom-privacy/delete-custom-privacy.component';

@Component({
  selector: 'app-custom-privacy',
  templateUrl: './custom-privacy.component.html',
  styleUrls: ['./custom-privacy.component.scss']
})
export class CustomPrivacyComponent implements OnInit {
  customerList: CodeName[];
  customerPlantList: CodeName[];
  unsubscribe = new Subject<void>();
  customer = new FormControl(null, [Validators.required]);

  customPrivacies = new BehaviorSubject<CustomPrivacy[]>(null);

  constructor(private commonApi: CommonApi, private api: CustomPrivacyApi, private modal: NgbModal, private readonly parent: SettingsChooserComponent) {
    this.commonApi.getCustomerList().subscribe(x => this.customerList = x);

    this.customer.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        tap(x => console.log(x)),
        switchMap((customerId: number) =>
          combineLatest(
            this.api.get(customerId),
            this.commonApi.getCustomerPlantList(this.customer.value)
          )
        )
      )
      .subscribe(x => {
        this.customPrivacies.next(x[0]);
        this.customerPlantList = x[1];
        this.parent.hideProgressbar()
      });
  }

  ngOnInit(): void {
  }

  async upsert(row: CustomPrivacy = null) {
    let insertNew: boolean = row == null;

    let ref = await this.modal.open(UpsertCustomPrivacyComponent);
    let toEdit: CustomPrivacy = GenericHelper.initFromInstance(row);

    ref.componentInstance.customPrivacy = toEdit;
    ref.componentInstance.plantList = this.customerPlantList;
    ref.componentInstance.title = insertNew ? "Add new custom privacy" : "Edit custom privacy";

    try {
      let result = await ref.result;
      this.api.upsert(this.customer.value, result)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get(this.customer.value))
        )
        .subscribe(newList => { this.parent.showSuccess(); this.customPrivacies.next(newList); this.parent.hideProgressbar() })

    } catch { }
  }

  async delete(row: CustomPrivacy) {
    let ref = await this.modal.open(DeleteCustomPrivacyComponent);

    try {
      let result = await ref.result;
      this.api.delete(row.id)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get(this.customer.value))
        )
        .subscribe(newList => { this.parent.showSuccess(); this.customPrivacies.next(newList); this.parent.hideProgressbar() })

    } catch { }
}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
