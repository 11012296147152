import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonApi } from '../../commons/common-api';
import { Observable, EMPTY } from 'rxjs';
import { FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { CodeName } from '../../commons/generic-helper';
import { distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { DateHelper } from '../../commons/date-helper';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent {
  constructor(private readonly http: HttpClient, private readonly api: CommonApi) { }
  private readonly today = new Date();
  private static readonly numval: ValidatorFn = a => {
    if (typeof a.value !== 'number' || a.value <= 0)
      return { invalid: true };
    return {};
  };
  public readonly form = new FormGroup({
    cust: new FormControl(null, ApiComponent.numval),
    plant: new FormControl({
      value: null,
      disabled: true
    }, ApiComponent.numval),
    from: new FormControl(DateHelper.fromJsDate(new Date(this.today.getFullYear(), this.today.getMonth() - 1, this.today.getDate())), Validators.required),
    to: new FormControl(DateHelper.fromJsDate(this.today), Validators.required)
  });
  public readonly apis: Observable<any[]> = this.http.get<any[]>('/api/api');
  public readonly customers: Observable<CodeName[]> = this.api.getCustomerList();
  public readonly plants: Observable<CodeName[]> = this.form.controls.cust.valueChanges.pipe(
    distinctUntilChanged(),
    switchMap(a => {
      this.form.controls.plant.setValue(null);
      if (typeof a === 'number' && a > 0) {
        this.form.controls.plant.enable();
        return this.api.getCustomerPlantList(a);
      } else {
        this.form.controls.plant.disable();
        return EMPTY;
      }
    })
  );
  public readonly data = this.form.valueChanges.pipe(
    filter(a => this.form.valid && typeof a.plant === 'number'),
    switchMap(a => this.http.get('/api/api/' + a.plant + '/' + DateHelper.toJsDate(this.form.controls.from.value).toISOString() + '/' + DateHelper.toJsDate(this.form.controls.to.value).toISOString()))
  );
}
