import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, EMPTY } from 'rxjs';
import { switchMap, filter, distinctUntilChanged, shareReplay, withLatestFrom } from 'rxjs/operators';
import { CommonApi } from '../../commons/common-api';
import { CodeName } from '../../commons/generic-helper';

const today = new Date();

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent {
  constructor(private readonly http: HttpClient, private readonly api: CommonApi) {
  }
  public readonly update = new Subject<void>();
  public readonly form = new FormGroup({
    from: new FormControl({ day: 1, month: 1, year: today.getFullYear() } as NgbDateStruct, Validators.required),
    to: new FormControl({ day: 31, month: 12, year: today.getFullYear() } as NgbDateStruct, Validators.required),
    cust: new FormControl(null, Validators.required),
    plant: new FormControl({
      value: null,
      disabled: true
    }, Validators.required)
  });
  public readonly customers: Observable<CodeName[]> = this.api.getCustomerList().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly plants: Observable<string[]> = this.form.controls.cust.valueChanges.pipe(
    distinctUntilChanged(),
    switchMap(a => {
      this.form.controls.plant.setValue(null);
      if (typeof a === 'number' && a > 0) {
        this.form.controls.plant.enable();
        return this.api.getCustomerPlantCodes(a);
      } else {
        this.form.controls.plant.disable();
        return EMPTY;
      }
    })
  );
  public readonly data = this.update.pipe(
    filter(() => this.form.valid),
    withLatestFrom(this.form.valueChanges, this.customers),
    switchMap(([_, a, b]) => this.http.post('/api/sms', {
      label: b.find(c => c.code === a.cust).name + '-' + a.plant,
      from: NgbDate2Iso(a.from),
      to: NgbDate2Iso(a.to)
    }))
  );
}
function NgbDate2Iso(ngbDate: NgbDateStruct): string {
  return `${ngbDate.year}-${zeros(ngbDate.month)}-${zeros(ngbDate.day)}T00:00:00.000`;
}
function zeros(a: number) {
  if (a < 10)
    return '0' + a;
  return a.toString();
}
