import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Plant } from '../plants-api';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Leaflet from 'leaflet';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-upsert-plant',
  templateUrl: './upsert-plant.component.html',
  styleUrls: ['./upsert-plant.component.scss']
})
export class UpsertPlantComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  unsubscribe = new Subject<void>();

  plant: Plant;
  title: string;

  map;
  searching: boolean = false;
  mapOptions;
  marker;

  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  address: FormControl;
  cap: FormControl;
  city: FormControl;
  state: FormControl;
  description: FormControl;
  lat: FormControl;
  lng: FormControl;

  searchAddress = new FormControl(null, [Validators.required]);

  constructor(private http: HttpClient, public readonly modal: NgbActiveModal, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    let initLat = this.plant.lat == null ? 44.511910 : this.plant.lat;
    let initLng = this.plant.lng == null ? 11.27836 : this.plant.lng;
    this.mapOptions = {
      layers: [
        Leaflet.tileLayer('http://maps.loadmanager.cloud/osm_tiles/{z}/{x}/{y}.png', { maxZoom: 19, attribution: '...' })
      ],
      zoom: 15,
      center: Leaflet.latLng(initLat, initLng)
    };
    this.marker = [Leaflet.marker([initLat, initLng])];


    this.address = new FormControl(this.plant.address, [Validators.required]);
    this.address.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.address = x);

    this.cap = new FormControl(this.plant.cap, [Validators.required]);
    this.cap.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.cap = x);

    this.city = new FormControl(this.plant.city, [Validators.required]);
    this.city.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.city = x);

    this.state = new FormControl(this.plant.state, [Validators.required]);
    this.state.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.state = x);

    this.description = new FormControl(this.plant.description, [Validators.required]);
    this.description.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.description = x);

    this.lat = new FormControl(this.plant.lat, [Validators.required]);
    this.lat.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.lat = parseFloat(x));

    this.lng = new FormControl(this.plant.lng, [Validators.required]);
    this.lng.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.plant.lng = parseFloat(x));

    this.formGroup.addControl("address", this.address);
    this.formGroup.addControl("cap", this.cap);
    this.formGroup.addControl("city", this.city);
    this.formGroup.addControl("state", this.state);
    this.formGroup.addControl("description", this.description);
    this.formGroup.addControl("lat", this.lat);
    this.formGroup.addControl("lng", this.lng);
  }

  search() {
    this.searching = true;
    this.http.get('https://nominatim.openstreetmap.org/search?accept-language=it&addressdetails=1&format=json&q=' + this.searchAddress.value)
      .subscribe((result: any[]) => {
        this.searching = false;
        this.saveLocation(result);
      });
  }

  onMapReady(map: Leaflet.Map) {
    this.map = map;
  }

  mapClick(event) {
    let latLng: Leaflet.LatLng = event.latlng;
    this.searching = true;
    this.http.get('https://nominatim.openstreetmap.org/reverse?accept-language=it&addressdetails=1&format=json&lat=' + latLng.lat + "&lon=" + latLng.lng)
      .subscribe((result: any[]) => {
        this.searching = false;
        this.saveLocation([result]);
        this.searchAddress.reset();
      });
  }

  saveLocation(osmResult) {
    if (osmResult && osmResult.length > 0) {
      let lat = osmResult[0].lat;
      let lng = osmResult[0].lon;
      this.marker = [(Leaflet.marker([lat, lng]))];
      this.map.setView(new Leaflet.LatLng(lat, lng), 15);

      let details = osmResult[0].address;
      console.log(details);

      this.lat.setValue(lat);
      this.lng.setValue(lng);
      this.state.setValue(details.country);
      this.cap.setValue(details.postcode);
      let city = "";
      city += details.suburb != undefined ? details.suburb + ", " : "";
      city += details.village != undefined ? details.village + ", " : "";
      city += details.town != undefined ? details.town + ", " : "";
      city += details.county != undefined ? details.county : "";
      this.city.setValue(city);
      let address = details.road + " " + details.house_number + ", ";
      address += city + ", ";
      address += details.country;
      this.address.setValue(address);
    }
  }
}
