import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CommonSettings, CommonSettingsApi } from './common-settings-api';

@Component({
  selector: 'app-common-settings',
  templateUrl: './common-settings.component.html',
  styleUrls: ['./common-settings.component.scss']
})
export class CommonSettingsComponent {
  fallback: boolean = false;

  constructor(private api: CommonSettingsApi) {
    this.api.getCommonSettings().pipe(filter(x => !!x && x.length != 0)).subscribe(x => {
        this.fallback = x.filter(y => y.key == "fallback")[0].value == "true" ? true : false;
    });
  }

  editFallback() {
    this.api.postCommonSettings(({ key: "fallback", value: JSON.stringify(this.fallback) })).subscribe();
  }

}
