import { Component, OnInit, OnDestroy } from '@angular/core';
import { AvgElement } from '../avgs-management-api';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeName } from '../../../commons/generic-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upsert-avg-element',
  templateUrl: './upsert-avg-element.component.html',
  styleUrls: ['./upsert-avg-element.component.scss']
})
export class UpsertAvgElementComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  unsubscribe = new Subject<void>();

  avgElement: AvgElement;
  plantList: CodeName[];
  title: string;

  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  plant: FormControl;
  minutes: FormControl;
  rollingDays: FormControl;
  frequency: FormControl;
  magicNumber: FormControl;
  overwrite: FormControl;

  constructor(public readonly modal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.plant = new FormControl(this.avgElement.plantId, [Validators.required]);
    this.plant.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.avgElement.plantId = parseInt(x));
    if (this.avgElement.plantId != null)
      this.plant.disable();

    this.minutes = new FormControl(this.avgElement.minutes, [Validators.required]);
    this.minutes.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.avgElement.minutes = x);

    this.rollingDays = new FormControl(this.avgElement.rollingDays);
    this.rollingDays.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.avgElement.rollingDays = x);

    this.frequency = new FormControl(this.avgElement.frequency);
    this.frequency.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.avgElement.frequency = x);

    this.magicNumber = new FormControl(this.avgElement.magicNumber);
    this.magicNumber.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.avgElement.magicNumber = x);

    this.overwrite = new FormControl(this.avgElement.overwrite, [Validators.required]);
    this.overwrite.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.avgElement.overwrite = x);

    this.formGroup.addControl("plant", this.plant);
    this.formGroup.addControl("minutes", this.minutes);
    this.formGroup.addControl("rollingDays", this.rollingDays);
    this.formGroup.addControl("frequency", this.frequency);
    this.formGroup.addControl("magicNumber", this.magicNumber);
    this.formGroup.addControl("overwrite", this.overwrite);
  }

}
