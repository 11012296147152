import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";

export class DateHelper {

  public static fromJsDate(date: Date): NgbDateStruct {
    if (date == null)
      return null;

    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  public static toJsDate(date: NgbDateStruct): Date {
    if (date == null)
      return null;

    return new Date(date.year, date.month - 1, date.day);
  }

  public static FromToValidator(formGroup: FormGroup) {
    const from: NgbDateStruct = formGroup.value.from;
    const to: NgbDateStruct = formGroup.value.to;

    if (from == null || to == null)
      return null;

    if (new Date(from.year, from.month - 1, from.day) > new Date(to.year, to.month - 1, to.day))
      return { crossDate: true };

    return null;
  }
}
