import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Key } from '../cryptokeys-api';
import { DateHelper } from '../../../commons/date-helper';
import { CodeName, GenericHelper } from '../../../commons/generic-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upsert-cryptokey',
  templateUrl: './upsert-cryptokey.component.html',
  styleUrls: ['./upsert-cryptokey.component.scss']
})
export class UpsertCryptokeyComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  unsubscribe = new Subject<void>();

  key: Key;
  usedKeys: string[];
  title: string;
  plantList: CodeName[];


  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  value: FormControl;
  from: FormControl;
  to: FormControl;
  plant: FormControl;

  minTo: NgbDateStruct;

  constructor(public readonly modal: NgbActiveModal, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    this.value = new FormControl(
      this.key.value,
      [Validators.required, cyptokeyFormatValidator(), GenericHelper.usedParameterValidator(this.usedKeys, this.key.value)]
    );
    this.value.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.key.value = x);
    if (this.key.value != null)
      this.value.disable();

    this.minTo = this.key.from == null ? null : DateHelper.fromJsDate(new Date(this.key.from));
    this.from = new FormControl(this.minTo, [Validators.required]);
    this.from.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.key.from = DateHelper.toJsDate(x);
      this.minTo = x;
    });

    this.to = new FormControl(this.key.to == null ? null : DateHelper.fromJsDate(new Date(this.key.to)), [Validators.required]);
    this.to.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.key.to = DateHelper.toJsDate(x));

    let plant = this.key.plantCode ? this.key.plantCode + "-" + this.key.plantDescription : null;
    this.plant = new FormControl(plant, [Validators.required]);
    this.plant.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      let values = x.split("-");
      this.key.plantCode = values[0];
      this.key.plantDescription = values[1];
    });
    if (this.key.plantCode != null)
      this.plant.disable();

    this.formGroup.addControl("value", this.value);
    this.formGroup.addControl("from", this.from);
    this.formGroup.addControl("to", this.to);
    this.formGroup.addControl("plant", this.plant);
    this.formGroup.setValidators([DateHelper.FromToValidator]);
  }

  createKey() {
    let keyLength = 50;
    let charNumber = 30;
    let digitNumber = 20;
    let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let validKey = false;
    let result: string = null;
    while (!validKey) {
      let tmp = [];
      let charCount = 0;
      let digitCount = 0;

      for (var i = 0; i < keyLength; i++) {
        let newChar = charSet.charAt(Math.floor(Math.random() * charSet.length));

        if (!isNaN(parseInt(newChar))) {
          digitCount++;
          if (digitCount == digitNumber) {
            charSet = charSet.substr(0, 26);
          }
        } else {
          charCount++;
          if (charCount == charNumber) {
            charSet = charSet.substr(26);
          }
        }

        tmp.push(newChar);
      }

      result = tmp.sort(function () { return 0.5 - Math.random() }).join(''); //shuffle
      validKey = this.usedKeys.indexOf(result) < 0;
    }

    this.value.reset();
    this.value.setValue(result);
  }

}

export function cyptokeyFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value == "" || control.value == null)
      return null;

    let key = (control.value as string).split("");
    let chars = key.filter(x => isNaN(parseInt(x)));
    let numbers = key.filter(x => !isNaN(parseInt(x)));

    if (key.length != 50)
      return { 'invalidFormat': { value: "key must be 50 chars long (found " + key.length + ")" } };
    if (numbers.length != 20)
      return { 'invalidFormat': { value: "key must contains 20 numbers(found " + numbers.length + ")" } };
    if (chars.length != 30)
      return { 'invalidFormat': { value: "key must contains 30 uppercase chars (found " + chars.length + ")" } };
    if (chars.find(x => "abcdefghijklmnopqrstuvwxyz".indexOf(x) >= 0) != null)
      return { 'invalidFormat': { value: "key must contains only uppercase chars" } };

    return null;
  };
}

