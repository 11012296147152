import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class GlobalWhitelistApi {
  private readonly requestUrl: string = '/api/GlobalWhitelist';

  constructor(private http: HttpClient) { }

  get(): Observable<Email[]> {
    return this.http.get<Email[]>(`${this.requestUrl}`);
  }

  upsert(x: Email): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, x);
  }

  delete(x: Email): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${x.address}`);
  }
}

export interface Email {
  address: string;
  statusCode: EmailStatus;
  statusDescription: string;
}

export enum EmailStatus {
  WhiteList,
  Existing,
  BlackList
}
