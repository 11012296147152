import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() confirmLabel: string;
  @Input() cancelLabel: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
