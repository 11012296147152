import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalWhitelistApi, Email } from './global-whitelist-api';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpsertEmailComponent } from './upsert-email/upsert-email.component';
import { DeleteEmailComponent } from './delete-email/delete-email.component';
import { catchError, switchMap, tap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { SettingsChooserComponent } from '../settings-chooser/settings-chooser.component';

@Component({
  selector: 'app-global-whitelist',
  templateUrl: './global-whitelist.component.html',
  styleUrls: ['./global-whitelist.component.scss']
})
export class GlobalWhitelistComponent implements OnInit,OnDestroy {
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  unsubscribe = new Subject<void>();

  emails = new BehaviorSubject<Email[]>(null);
  filter: FormControl = new FormControl(null);
  allEmails: Email[] = null;

  pageSize: number = 15;
  page: number = 1;
  oldPage: number = null;

  constructor(private api: GlobalWhitelistApi, private readonly parent: SettingsChooserComponent, private modal: NgbModal) {
    this.api.get()
      .pipe(tap(() => this.parent.showProgressbar()))
      .subscribe(x => {this.parent.hideProgressbar(); this.emails.next(x); this.allEmails = x; });

    this.filter.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((filter: string) => {
        if (filter == null || filter.length == 0) {
          this.emails.next(this.allEmails);
          this.page = this.oldPage;
          this.oldPage = null;
        }
        else {
          this.oldPage = this.oldPage == null ? this.page : this.oldPage;
          this.emails.next(this.allEmails.filter(b =>
            b.address.toLowerCase().startsWith(filter.toLowerCase().trim())
          ));
          this.page = 1;
        }
      });
  }

  ngOnInit(): void {
  }

  async upsert(x: Email = null) {
    let insertNew: boolean = x == null;

    let ref = await this.modal.open(UpsertEmailComponent);
    let toEdit: Email = {
      address: insertNew ? null : x.address,
      statusCode: insertNew ? null : x.statusCode,
      statusDescription: insertNew ? null : x.statusDescription
    }

    ref.componentInstance.email = toEdit;
    ref.componentInstance.title = insertNew ? "Add new email" : "Edit " + x.address;
    ref.componentInstance.usedAddresses = this.emails.value.map(x => x.address);

    try {
      let result = await ref.result;
      this.api.upsert(toEdit)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get())
        )
        .subscribe(newList => { this.parent.showSuccess(); this.parent.hideProgressbar(); this.emails.next(newList); this.allEmails = newList; })

    } catch{ }
  }

  async delete(x: Email) {
    let ref = await this.modal.open(DeleteEmailComponent);
    ref.componentInstance.email = x;

    try {
      let result = await ref.result;
      this.api.delete(x)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get())
        )
        .subscribe(newList => { this.parent.showSuccess(); this.parent.hideProgressbar(); this.emails.next(newList); this.allEmails = newList; })

    } catch{ }
  }
}
