import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlantsApi, Plant } from './plants-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsChooserComponent } from '../settings-chooser/settings-chooser.component';
import { FormControl, Validators } from '@angular/forms';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { takeUntil, switchMap, tap, catchError } from 'rxjs/operators';
import { CodeName, GenericHelper } from '../../commons/generic-helper';
import { UpsertPlantComponent } from './upsert-plant/upsert-plant.component';
import { DeletePlantComponent } from './delete-plant/delete-plant.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonApi } from '../../commons/common-api';

@Component({
  selector: 'app-plants',
  templateUrl: './plants.component.html',
  styleUrls: ['./plants.component.scss']
})
export class PlantsComponent implements OnInit, OnDestroy {

  plants = new BehaviorSubject<Plant[]>(null);
  customerList: CodeName[];
  unsubscribe = new Subject<void>();
  customer = new FormControl(null, [Validators.required]);

  constructor(private commonApi: CommonApi, private api: PlantsApi, private modal: NgbModal, private readonly parent: SettingsChooserComponent) {
    this.commonApi.getCustomerList().subscribe(x => this.customerList = x);

    this.customer.valueChanges
      .pipe(
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        tap(x => console.log(x)),
        switchMap((customerId: number) => this.api.getPlants(customerId))
      )
      .subscribe(x => { this.plants.next(x); this.parent.hideProgressbar() });
  }

  ngOnInit(): void {
  }

  async upsert(row: Plant) {
    let insertNew: boolean = row == null;

    let ref = await this.modal.open(UpsertPlantComponent, { size: "xl" });
    let toEdit: Plant = GenericHelper.initFromInstance(row);
    if (insertNew) {
      toEdit["id"] = null;
      toEdit["hidden"] = false;
      toEdit["externalCode"] = null;
    }

    ref.componentInstance.plant = toEdit;
    ref.componentInstance.title = insertNew ? "Add new plant" : "Edit " + row.description + " - " + row.externalCode;

    try {
      let result = await ref.result;
      this.api.upsert(this.customer.value, toEdit)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.getPlants(this.customer.value))
        )
        .subscribe(newList => { this.parent.showSuccess(); this.plants.next(newList); this.parent.hideProgressbar() })

    } catch{ }
  }

  async delete(row: Plant) {
    let ref = await this.modal.open(DeletePlantComponent);
    ref.componentInstance.plant = row;

    try {
      let result = await ref.result;
      this.api.delete(this.customer.value, row)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.getPlants(this.customer.value))
        )
        .subscribe(newList => { this.parent.showSuccess(); this.plants.next(newList); this.parent.hideProgressbar() })

    } catch{ }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
