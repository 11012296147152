import { ValidatorFn, AbstractControl } from "@angular/forms";

export class GenericHelper {

  public static usedParameterValidator(usedCodes: string[], current: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = usedCodes.indexOf(control.value) < 0 || current == control.value;
      return valid ? null : { 'used': { value: control.value } };
    };
  }

  static initFromInstance(i: any, excludedProperties: string[] = []): any {
    let copy = {};
    for (let property in i)
      if (excludedProperties.indexOf(property) < 0)
        copy[property] = i[property];

    return copy;

  }

  static getEnumOptionsList(enumType): CodeName[] {
    let typeList : CodeName[] = [];
    for (var type in enumType) {
      if (isNaN(parseInt(type)))
        typeList.push({ code: parseInt(enumType[type]), name: type });
    }
    return typeList;
  }

}

export interface CodeName {
  code: number;
  name: string;
}
