import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { CodeName } from "./generic-helper";
import { TypeEnum } from "./default-types-values";

@Injectable()
export class FileApi {
  private readonly requestUrl: string = '/api/File';

  constructor(private http: HttpClient) { }

  getIcon(type: TypeEnum, customerId: string, typeCode: string): Observable<Blob> {
    return this.http.get(`${this.requestUrl}/GetIcon/${type}/${customerId}/${typeCode}`, { responseType: 'blob'});
  }

  uploadIcon(form: FormData): Observable<any> {
    return this.http.post(`${this.requestUrl}/UploadIcon/`, form, { responseType: 'blob' });
  }
  
}

