import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeEnum } from "../default-types-values";
import { FileApi } from "../file-api";

@Component({
  selector: 'app-add-common-icon',
  templateUrl: './add-common-icon.component.html',
  styleUrls: ['./add-common-icon.component.scss']
})
export class AddCommonIconComponent implements OnInit {

  iconFile: File = null;
  code: string = null;
  reader = new FileReader();
  type: TypeEnum;
  commonIconError: string = null;

  constructor(public readonly modal: NgbActiveModal, private fileApi: FileApi) {

  }

  ngOnInit() {
    this.commonIconError = null;
  }

  onFileSelected(event) {
    this.iconFile = event.target.files[0];
  }

  closeModal() {
    if (this.code && this.iconFile) {
      this.commonIconError = null;
      this.reader.readAsDataURL(this.iconFile);
      const formData: FormData = new FormData();
      formData.set('type', this.type.toString());
      formData.set('customerCode', '');
      formData.set('typeCode', this.code);
      formData.set('icon', this.iconFile);
      this.fileApi.uploadIcon(formData).subscribe(_ => {
        this.modal.close()
      }, (error: HttpErrorResponse) => {
        if (error.status == 400) {
          this.commonIconError = "Il codice è già presente";
        }
      });
    }
  }
}
