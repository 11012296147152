import { Component, OnInit } from '@angular/core';
import { Plant } from '../plants-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-plant',
  templateUrl: './delete-plant.component.html',
  styleUrls: ['./delete-plant.component.scss']
})
export class DeletePlantComponent implements OnInit {

  plant: Plant;

  constructor(public readonly modal: NgbActiveModal) {}

  ngOnInit(): void {
  }

}
