import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subject, fromEvent, of } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FileApi } from '../file-api';
import { Icon, TypeEnum } from '../default-types-values';

@Component({
  selector: 'app-add-default-type',
  templateUrl: './add-default-type.component.html',
  styleUrls: ['./add-default-type.component.scss']
})
export class AddDefaultTypeComponent implements OnInit, OnDestroy {
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false
  };

  unsubscribe = new Subject<void>();
  public label: string = 'Add';
  newType: any;
  referenceCodesList: any;
  usedCodes: string[];
  maxCodePropertyLength: number;
  propertyList: any[];
  iconUpload: boolean;
  type: TypeEnum;
  customerCode: string;
  fileUploadControl: FormControl = new FormControl();
  reader = new FileReader();
  imgSrc: string;
  commonIconList: Icon[];
  selectedCommonIcon: Icon = null;

  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  constructor(public readonly modal: NgbActiveModal, private formBuilder: FormBuilder, private fileApi: FileApi) {
    this.reader.onload = (_event) => {
      this.imgSrc = <string>this.reader.result;
    }
  }

  ngOnInit(): void {
    console.log(this.commonIconList);
    this.propertyList = [];
    for (let property in this.newType) {
      if (this.newType[property])
        this.label = 'Edit';
      if (property == 'used') {
        if (this.newType[property] == true)
          this.formGroup.controls['code'].disable();
        continue;
      }
      let validators = property.toLowerCase() == "code" ? [Validators.required, usedCodeValidator(this.usedCodes, this.newType[property])] : property.toLowerCase() == "limit" ? null : [Validators.required];
      if (this.maxCodePropertyLength != null && property.toLowerCase() == "code")
        validators.push(Validators.maxLength(this.maxCodePropertyLength))
      let fc = new FormControl(this.newType[property], validators);
      let type = property == "x" || property == "y" || property == "z" || property == "weight" || property == "limit" ? "number" : (property == "destinationTypeCode" ? "multiselect" : "text");

      fc.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
        this.newType[property] = type == "multiselect" ? x[0].code : type == 'number' ? Number.parseFloat(x) : x;
      });

      this.formGroup.addControl(property, fc);

      this.propertyList.push({
        control: fc,
        name: property,
        type: type
      });
    }
    this.fileApi.getIcon(this.type, this.customerCode, this.newType['code'])
      .pipe(
        catchError(err => of(null))
       )
      .subscribe(blob => { if (blob) this.reader.readAsDataURL(blob) });
  }

  public fileUploaded(event: InputEvent) {
    const file: Blob = (<any>event.target).files[0];
    this.reader.readAsDataURL(file);
    //this.fileApi.getIcon(TypeEnum.HandlingUnit, 'DSV', 'pallet').subscribe(blob => this.reader.readAsDataURL(blob));
    const formData: FormData = new FormData();
    formData.set('type', this.type.toString());
    formData.set('customerCode', this.customerCode);
    formData.set('typeCode', this.newType['code']);
    formData.set('icon', file);
    this.fileApi.uploadIcon(formData).subscribe(_ => console.log());
  }

  public get fileUploadEnabled() {
    return this.type != TypeEnum.Destination && this.type != TypeEnum.GateType;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  typeHasIcon(): boolean {
    if (this.type) {
      return !(this.type == TypeEnum.Destination || this.type == TypeEnum.GateType);
    } else {
      if (this.type == TypeEnum.HandlingUnit)
        return true;
      return false;
    }
  }

  selectCommonIcon(icon: Icon) {
    this.selectedCommonIcon = icon;
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  closeModal() {
    if (this.selectedCommonIcon) {
      var blob = this.b64toBlob(this.selectedCommonIcon.image);
      var blobUrl = URL.createObjectURL(blob);
      const formData: FormData = new FormData();
      formData.set('type', this.type.toString());
      formData.set('customerCode', this.customerCode);
      formData.set('typeCode', this.newType['code']);
      formData.set('icon', blob);
      this.fileApi.uploadIcon(formData).subscribe(_ => console.log());
    }
    this.modal.close();
  }
}

export function usedCodeValidator(usedCodes: string[], current: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = current == control.value || usedCodes.indexOf(control.value) < 0;
    return valid ? null : { 'usedCode': { value: control.value } };
  };
}
