import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import {  Destination, TruckType, TripType, Product, GateType } from "../../commons/default-types-values";
import { CodeName } from "../../commons/generic-helper";

@Injectable()
export class CustomersApi {
  private readonly requestUrl: string = '/api/customers';

  constructor(private http: HttpClient) { }

  getCustomerList(): Observable<CodeName[]> {
    return this.http.get<CodeName[]>(`${this.requestUrl}`);
  }

  getCustomer(id: number): Observable<Customer> {
    return this.http.get<Customer>(`${this.requestUrl}/GetCustomer/${id}`);
  }

  createCustomer(c: Customer): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, c);
  }

  updateCustomer(c: Customer): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}/Update`, c);
  }
}

export interface Customer {
  id: number;
  code?: string;
  name: string;
  password: string;
  email: string;
  advancedSettings: AdvancedSettings;
}

export interface AdvancedSettings {
  destinations: Destination[];
  truckTypes: TruckType[];
  tripTypes: TripType[];
  products: Product[];
  gateTypes: GateType[];
}
