import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CustomPrivacyApi {
  private readonly requestUrl: string = '/api/customPrivacy';

  constructor(private http: HttpClient) { }

  get(customerId: number): Observable<CustomPrivacy[]> {
    return this.http.get<CustomPrivacy[]>(`${this.requestUrl}/${customerId}`);
  }

  upsert(identityCustomerId, p: CustomPrivacy): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}/${identityCustomerId}`, p);
  }

  delete(id: number): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${id}`);
  }

}

export interface CustomPrivacy {
  id: number;
  privacyIt: string;
  privacyEn: string;
  privacyLinkIt: string;
  privacyLinkEn: string;
  cookieIt: string;
  cookieEn: string;
  cookieLinkIt: string;
  cookieLinkEn: string;
  identityCustomerId: number;
}
