import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Email } from '../global-whitelist-api';

@Component({
  selector: 'app-delete-email',
  templateUrl: './delete-email.component.html',
  styleUrls: ['./delete-email.component.scss']
})
export class DeleteEmailComponent implements OnInit {

  @Input() email: Email;

  constructor(public readonly modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
