import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Email, EmailStatus } from '../global-whitelist-api';
import { GenericHelper, CodeName } from '../../../commons/generic-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upsert-email',
  templateUrl: './upsert-email.component.html',
  styleUrls: ['./upsert-email.component.scss']
})
export class UpsertEmailComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  unsubscribe = new Subject<void>();

  @Input() email: Email;
  @Input() title: string;
  @Input() usedAddresses: string[];
  statusList: CodeName[];

  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  address: FormControl;
  status: FormControl;

  constructor(public readonly modal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.statusList = GenericHelper.getEnumOptionsList(EmailStatus);

    this.address = new FormControl(this.email.address, [Validators.required, GenericHelper.usedParameterValidator(this.usedAddresses, this.email.address)]);
    this.address.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.email.address = x);

    this.status = new FormControl(this.email.statusCode, [Validators.required]);
    this.status.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((code: string) => {
      this.email.statusCode = parseInt(code);
      this.email.statusDescription = this.statusList.find(x => x.code == this.email.statusCode).name;
    });

    this.formGroup.addControl("address", this.address);
    this.formGroup.addControl("status", this.status);
  }

}
