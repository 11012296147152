import { BehaviorSubject } from "rxjs";
import { CodeName } from "./generic-helper";

export class DefaultTypesValues {
  static destinations = new BehaviorSubject<Destination[]>([
    { code: "01", name: "IT", used: false }
  ]);

  static truckTypes = new BehaviorSubject<TruckType[]>([
    { code: "C20", name: "Container 20 piedi", used: false },
    { code: "C40", name: "Container 40 piedi", used: false }
  ]);

  static tripTypes = new BehaviorSubject<TripType[]>([
    { code: "01", name: "Road", used: false },
    { code: "02", name: "Train", used: false },
    { code: "03", name: "Air", used: false },
    { code: "04", name: "Sea", used: false },
  ]);

  static products = new BehaviorSubject<Product[]>([
    { code: "pallet", name: "pallet", x: 0, y: 0, z: 0, weight: 0, used: false, limit: null },
    { code: "box", name: "box", x: 0, y: 0, z: 0, weight: 0, used: false, limit: null },
    { code: "FTL", name: "FTL", x: 0, y: 0, z: 0, weight: 0, used: false, limit: null }
  ]);

  static gates = new BehaviorSubject<GateType[]>([
    { code: "1", name: "test", used: false },
  ]);
}

export interface CodeNameUsed {
  code: string;
  name: string;
  used: boolean;
}

export interface Destination extends CodeNameUsed {
}

export interface TruckType extends CodeNameUsed {
}

export interface GateType extends CodeNameUsed {
}

export interface TripType extends CodeNameUsed {
}

export interface Product extends CodeNameUsed {
  x: number;
  y: number;
  z: number;
  weight: number;
  limit: number;
}

export enum TypeEnum {
  HandlingUnit,
  TruckType,
  TripType,
  Destination,
  GateType
}

export interface CustomerIcon {
  code: string;
  name: string
  handlingUnitIcons: Icon[];
  truckTypeIcons: Icon[];
  tripTypesIcons: Icon[];
}

export interface Icon {
  code: string;
  image: string
}

