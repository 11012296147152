import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Key } from '../cryptokeys-api';

@Component({
  selector: 'app-delete-cryptokey',
  templateUrl: './delete-cryptokey.component.html',
  styleUrls: ['./delete-cryptokey.component.scss']
})
export class DeleteCryptokeyComponent implements OnInit {

  key: Key;

  constructor(public readonly modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
