import { Component, OnInit } from '@angular/core';
import { DocksUsageApi, IDocksUsage } from './docks-usage-api';
import { BehaviorSubject, of, Observable, EMPTY } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateHelper } from '../../commons/date-helper';
import { catchError, shareReplay, distinctUntilChanged, switchMap, tap, filter } from 'rxjs/operators';
import { CodeName } from '../../commons/generic-helper';
import { CommonApi } from '../../commons/common-api';

@Component({
  selector: 'app-docks-usage',
  templateUrl: './docks-usage.component.html',
  styleUrls: ['./docks-usage.component.scss']
})
export class DocksUsageComponent {
  today = new Date();
  docks: BehaviorSubject<IDocksUsage[]> = new BehaviorSubject<IDocksUsage[]>(null);
  fromControl: FormControl = new FormControl(DateHelper.fromJsDate(new Date(this.today.getFullYear(), this.today.getMonth() - 1, this.today.getDate())), Validators.required);
  toControl: FormControl = new FormControl(DateHelper.fromJsDate(this.today), Validators.required);
  cust: FormControl = new FormControl(null, Validators.required);
  plant: FormControl = new FormControl({ value: null, disabled: true}, Validators.required)

  constructor(private docksUsageApi: DocksUsageApi, private commonApi: CommonApi) {
  }
  public readonly customers: Observable<CodeName[]> = this.commonApi.getCustomerList();
  public readonly plants: Observable<string[]> = this.cust.valueChanges.pipe(
    distinctUntilChanged(),
    switchMap(a => {
      this.plant.setValue(null);
      if (typeof a === 'number' && a > 0) {
        this.plant.enable();
        return this.commonApi.getCustomerPlantCodes(a);
      } else {
        this.plant.disable();
        return EMPTY;
      }
    })
  );
  public getUsage(){
    this.docksUsageApi.get(
      DateHelper.toJsDate(this.fromControl.value),
      DateHelper.toJsDate(this.toControl.value),
      this.cust.value,
      <string>this.plant.value ?? "All"
    )
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(<IDocksUsage[]>[]);
        })
      )
      .subscribe(x => this.docks.next(x));
  }
}
