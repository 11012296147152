import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CustomersApi, Customer } from '../customers/customers-api';
import { map, switchMap, catchError, takeUntil, pluck, tap } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripType, Product, Destination, TruckType, GateType, TypeEnum, CustomerIcon } from '../../commons/default-types-values';
import { AddDefaultTypeComponent } from '../../commons/add-default-type/add-default-type.component';
import { HttpErrorResponse } from '@angular/common/http';
import { of, Subject, fromEvent } from 'rxjs';
import { IChangeUrl } from '../settings-chooser/deactivate.guard';
import { SettingsChooserComponent } from '../settings-chooser/settings-chooser.component';
import { IconApi } from '../icons/icon-api';
import { icon } from 'leaflet';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements IChangeUrl, OnDestroy {
  unsubscribe = new Subject<void>();
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false
  };

  customer: Customer;
  private customerId: number;
  unsavedChanges: boolean = false;
  commonIcons: CustomerIcon[];

  constructor(private activatedRoute: ActivatedRoute, public router: Router, private api: CustomersApi, private readonly parent: SettingsChooserComponent, private modal: NgbModal, private iconApi: IconApi) {
    this.parent.showProgressbar();
    this.activatedRoute.params
      .pipe(
        takeUntil(this.unsubscribe),
        pluck('customerId'),
        tap((customerId: number) => this.customerId = customerId),
        switchMap((customerId: number) => this.api.getCustomer(customerId))
      )
      .subscribe(x => {
        this.customer = x;
        this.parent.hideProgressbar();
      });

    this.iconApi.getCommonIcons().subscribe(x => this.commonIcons = x);
  }

  public async addDestination() {
    let usedCodes = this.customer.advancedSettings.destinations.map(x => x.code);
    let toAdd: Destination = { code: null, name: null, used: false };
    let res = await this.addType(usedCodes, toAdd, null, 50, { type: TypeEnum.Destination, customerCode: this.customer.code});
    if (res) {
      this.customer.advancedSettings.destinations = this.customer.advancedSettings.destinations.concat([toAdd]);
    }
  }

  public async editDestination(elem: Destination) {
    let usedCodes = this.customer.advancedSettings.destinations.map(x => x.code);
    let toEdit: Destination = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 50, { type: TypeEnum.Destination, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.destinations.splice(this.customer.advancedSettings.destinations.indexOf(elem), 1);
      this.customer.advancedSettings.destinations.push(toEdit);
    }
  }

  public async addGateType() {
    let usedCodes = this.customer.advancedSettings.gateTypes.map(x => x.code);
    let toAdd: GateType = { code: null, name: null, used: false };
    let res = await this.addType(usedCodes, toAdd, null, 5, { type: TypeEnum.GateType, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.gateTypes = this.customer.advancedSettings.gateTypes.concat([toAdd]);
    }
  }
  public async editGateType(elem: GateType) {
    let usedCodes = this.customer.advancedSettings.gateTypes.map(x => x.code);
    let toEdit: GateType = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 5, { type: TypeEnum.GateType, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.gateTypes.splice(this.customer.advancedSettings.gateTypes.indexOf(elem), 1);
      this.customer.advancedSettings.gateTypes.push(toEdit);
    }
  }

  public async addTruckType() {
    let usedCodes = this.customer.advancedSettings.truckTypes.map(x => x.code);
    let toAdd: TruckType = { code: null, name: null, used: false };
    let res = await this.addType(usedCodes, toAdd, null, 5, { type: TypeEnum.TruckType, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.truckTypes = this.customer.advancedSettings.truckTypes.concat([toAdd]);
    }
  }

  public async editTruckType(elem: TruckType) {
    let usedCodes = this.customer.advancedSettings.truckTypes.map(x => x.code);
    let toEdit: TruckType = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 5, { type: TypeEnum.TruckType, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.truckTypes.splice(this.customer.advancedSettings.truckTypes.indexOf(elem), 1);
      this.customer.advancedSettings.truckTypes.push(toEdit);
    }
  }

  public async addTripType() {
    let usedCodes = this.customer.advancedSettings.tripTypes.map(x => x.code);
    let toAdd: TripType = { code: null, name: null, used:false };
    let res = await this.addType(usedCodes, toAdd, null, 5, { type: TypeEnum.TripType, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.tripTypes = this.customer.advancedSettings.tripTypes.concat([toAdd]);
    }
  }

  public async editTripType(elem: TripType) {
    let usedCodes = this.customer.advancedSettings.tripTypes.map(x => x.code);
    let toEdit: TripType = { code: elem.code, name: elem.name, used: elem.used };
    let res = await this.addType(usedCodes, toEdit, null, 5, { type: TypeEnum.TripType, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.tripTypes.splice(this.customer.advancedSettings.tripTypes.indexOf(elem), 1);
      this.customer.advancedSettings.tripTypes.push(toEdit);
    }
  }

  public async addProduct() {
    let usedCodes = this.customer.advancedSettings.products.map(x => x.code);
    let toAdd: Product = { code: null, name: null, x: 0, weight: 0, y: 0, z: 0, used: false, limit: null };
    let res = await this.addType(usedCodes, toAdd, null, 50, { type: TypeEnum.HandlingUnit, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.products = this.customer.advancedSettings.products.concat([toAdd]);
    }
  }

  public async editProduct(elem: Product) {
    let usedCodes = this.customer.advancedSettings.products.map(x => x.code);
    let toEdit: Product = { code: elem.code, name: elem.name, x: elem.x, weight: elem.weight, y: elem.y, z: elem.z, used: elem.used, limit: elem.limit };
    let res = await this.addType(usedCodes, toEdit, null, 50, { type: TypeEnum.HandlingUnit, customerCode: this.customer.code });
    if (res) {
      this.customer.advancedSettings.products.splice(this.customer.advancedSettings.products.indexOf(elem), 1);
      this.customer.advancedSettings.products.push(toEdit);
    }
  }

  public async addType(usedCodes: string[], newType, referenceCodesList: any = null, maxCodePropertyLength: number = null, iconOptions?: { type: TypeEnum, customerCode: string}) {
    let ref = await this.modal.open(AddDefaultTypeComponent);

    ref.componentInstance.newType = newType;
    ref.componentInstance.usedCodes = usedCodes;
    ref.componentInstance.referenceCodesList = referenceCodesList;
    ref.componentInstance.maxCodePropertyLength = maxCodePropertyLength;
    ref.componentInstance.type = iconOptions.type;
    ref.componentInstance.customerCode = iconOptions.customerCode;
    if (iconOptions.type == TypeEnum.HandlingUnit && this.commonIcons && this.commonIcons.length > 0) {
      ref.componentInstance.commonIconList = this.commonIcons[0].handlingUnitIcons;
    }
    if (iconOptions.type == TypeEnum.TripType && this.commonIcons && this.commonIcons.length > 0) {
      ref.componentInstance.commonIconList = this.commonIcons[0].tripTypesIcons;
    }
    if (iconOptions.type == TypeEnum.TruckType && this.commonIcons && this.commonIcons.length > 0) {
      ref.componentInstance.commonIconList = this.commonIcons[0].truckTypeIcons;
    }

    try {
      await ref.result;
      return true;
    } catch{ return false; }
  }

  private update() {
    this.parent.showProgressbar();
    this.api.updateCustomer(this.customer)
      .pipe(
        catchError((x: HttpErrorResponse) => {
          console.error("❌ " + x.error);
          this.parent.showError();
          this.unsavedChanges = false;
          return of(null);
        }),
        switchMap(_ => this.api.getCustomer(this.customerId))
      ).subscribe(x => {
        this.parent.showSuccess();
        this.unsavedChanges = false;
        this.parent.hideProgressbar();
        this.customer = x;
      })
  }

  public changeUrl() {
    return !this.unsavedChanges;
  }

  saveChanges() { this.update(); }

  public remove<T>(elem: T, list: T[]) {
    const idx = list.indexOf(elem);
    if (idx > -1)
      list.splice(idx, 1);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
