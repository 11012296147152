import { Component, OnInit, OnDestroy } from '@angular/core';
import { Rule, RulesManagementApi, PlantOptions } from '../rules-management-api';
import { CodeName } from '../../../commons/generic-helper';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateHelper } from '../../../commons/date-helper';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-upsert-rule',
  templateUrl: './upsert-rule.component.html',
  styleUrls: ['./upsert-rule.component.scss']
})
export class UpsertRuleComponent implements OnInit, OnDestroy {

  rule: Rule;
  plantList: CodeName[];

  products: CodeName[] = [];
  truckTypes: CodeName[] = [];
  tripTypes: CodeName[] = [];
  destinations: CodeName[] = [];

  title: string;

  unsubscribe = new Subject<void>();
  downloadOptions = new Subject<void>();

  public formGroup: FormGroup = this.formBuilder.group({
    hideRequired: false,
    floatLabel: 'auto'
  });

  plant: FormControl;
  minutes: FormControl;
  truckType: FormControl;
  destinationCountry: FormControl;
  deliveryMethod: FormControl;
  package: FormControl;
  action: FormControl;
  rollingDays: FormControl;
  frequency: FormControl;
  magicNumber: FormControl;
  overwrite: FormControl;
  fixedtime: FormControl;

  constructor(private api: RulesManagementApi, public readonly modal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.downloadOptions
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(x => this.api.getPlantOptions(this.rule.customerId, this.rule.plantCode))
      )
      .subscribe((x: PlantOptions) => {
        this.products = x.products;
        this.destinations = x.destinations;
        this.truckTypes = x.truckTypes;
        this.tripTypes = x.tripTypes;
        this.package.enable();
        this.deliveryMethod.enable();
        this.destinationCountry.enable();
        this.truckType.enable();
      });
  }

  ngOnInit(): void {

    this.plant = new FormControl(this.rule.plantId, [Validators.required]);
    this.plant.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.rule.plantId = parseInt(x);
      let plantCode = this.plantList
        .find(y => y.code == this.rule.plantId).name.split("-")[0]; //name e' nel formato ExternalCode + "-" + Description
      this.rule.plantCode = plantCode;
      this.downloadOptions.next();
    });

    if (this.rule.plantCode != null)
      this.downloadOptions.next();

    this.minutes = new FormControl(this.rule.minutes, [Validators.required]);
    this.minutes.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.minutes = x);

    this.truckType = new FormControl(this.rule.truckType);
    this.truckType.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.truckType = x);

    this.destinationCountry = new FormControl(this.rule.destinationCountry);
    this.destinationCountry.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.destinationCountry = x);

    this.deliveryMethod = new FormControl(this.rule.deliveryMethod);
    this.deliveryMethod.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.deliveryMethod = x);

    this.package = new FormControl(this.rule.packageType);
    this.package.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.packageType = x);

    this.action = new FormControl(this.rule.action);
    this.action.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.action = parseInt(x));

    this.rollingDays = new FormControl(this.rule.rollingDays);
    this.rollingDays.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.rollingDays = x);

    this.frequency = new FormControl(this.rule.frequency);
    this.frequency.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.frequency = x);

    this.magicNumber = new FormControl(this.rule.magicNumber);
    this.magicNumber.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.magicNumber = x);

    this.overwrite = new FormControl(this.rule.overwrite);
    this.overwrite.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.overwrite = x);

    this.fixedtime = new FormControl(this.rule.fixedTime);
    this.fixedtime.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(x => this.rule.fixedTime = x);

    this.package.disable();
    this.deliveryMethod.disable();
    this.destinationCountry.disable();
    this.truckType.disable();

    this.formGroup.addControl("plant", this.plant);
    this.formGroup.addControl("minutes", this.minutes);
    this.formGroup.addControl("truckType", this.truckType);
    this.formGroup.addControl("destinationCountry", this.destinationCountry);
    this.formGroup.addControl("deliveryMethod", this.deliveryMethod);
    this.formGroup.addControl("package", this.package);
    this.formGroup.addControl("action", this.action);
    this.formGroup.addControl("rollingDays", this.rollingDays);
    this.formGroup.addControl("frequency", this.frequency);
    this.formGroup.addControl("magicNumber", this.magicNumber);
    this.formGroup.addControl("overwrite", this.overwrite);
    this.formGroup.addControl("fixedtime", this.fixedtime);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
