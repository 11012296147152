import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { CodeName, GenericHelper } from '../../commons/generic-helper';
import { Validators, FormControl } from '@angular/forms';
import { AvgsManagementApi, AvgElement } from './avgs-management-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrainyHelperChooserComponent } from '../brainy-helper-chooser/brainy-helper-chooser.component';
import { tap, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UpsertAvgElementComponent } from './upsert-avg-element/upsert-avg-element.component';
import { DeleteAvgElementComponent } from './delete-avg-element/delete-avg-element.component';
import { CommonApi } from '../../commons/common-api';

@Component({
  selector: 'app-avgs-management',
  templateUrl: './avgs-management.component.html',
  styleUrls: ['./avgs-management.component.scss']
})
export class AvgsManagementComponent implements OnInit, OnDestroy {

  customerList: CodeName[];
  customerPlantsList: CodeName[];
  unsubscribe = new Subject<void>();
  customer = new FormControl(null, [Validators.required]);

  avgElementList = new BehaviorSubject<AvgElement[]>(null);

  constructor(private commonApi: CommonApi, private api: AvgsManagementApi, private modal: NgbModal, private readonly parent: BrainyHelperChooserComponent) {
    this.commonApi.getCustomerList().subscribe(x => this.customerList = x);

    this.customer.valueChanges
      .pipe(
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        tap(x => console.log(x)),
        switchMap((customerId: number) =>
          combineLatest(
            this.api.get(customerId),
            this.commonApi.getCustomerPlantList(customerId)
          )
        )
      )
      .subscribe(x => {
        this.avgElementList.next(x[0]);
        this.customerPlantsList = x[1];
        this.parent.hideProgressbar()
      });
  }

  ngOnInit(): void {
  }

  async upsert(row: AvgElement = null) {
    let insertNew: boolean = row == null;

    let ref = await this.modal.open(UpsertAvgElementComponent);
    let toEdit: AvgElement = GenericHelper.initFromInstance(row);
    if (insertNew) {
      toEdit.id = -1;
      toEdit.customerId = parseInt(this.customer.value);
      toEdit.overwrite = false;
    }

    let usedPlants = this.avgElementList.value.map(x => x.plantId);
    ref.componentInstance.avgElement = toEdit;
    ref.componentInstance.plantList = insertNew ?
      this.customerPlantsList.filter(x => usedPlants.indexOf(x.code) < 0) :
      this.customerPlantsList.filter(x => x.code == toEdit.plantId);
    ref.componentInstance.title = insertNew ? "Add new Avg rule" : "Edit avg rule";

    try {
      let result = await ref.result;
      this.api.upsert(toEdit)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get(this.customer.value))
        )
        .subscribe(newList => { this.parent.showSuccess(); this.avgElementList.next(newList); this.parent.hideProgressbar() })

    } catch{ }
  }

  async delete(row: AvgElement) {
    let ref = await this.modal.open(DeleteAvgElementComponent);
    try {
      let result = await ref.result;
      this.api.delete(row)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get(this.customer.value))
        )
        .subscribe(newList => { this.parent.showSuccess();this.avgElementList.next(newList); this.parent.hideProgressbar() })

    } catch{ }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
