import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocksUsageApi {
  private readonly requestUrl: string = '/api/docksUsage';

  constructor(private http: HttpClient) { }

  get(from: Date, to: Date, custId: number, plantCode?: string): Observable<IDocksUsage[]> {
    return this.http.get<IDocksUsage[]>(`${this.requestUrl}/${from.toISOString()}/${to.toISOString()}/${custId}/${plantCode}`);
  }
}

export interface IDocksUsage {
  customerDescription: string;
  plantDescription: string;
  dockName: string;
  count: number;
}
