import { Component, OnInit } from '@angular/core';
import { IconApi } from './icon-api';
import { Observable } from 'rxjs';
import { CustomerIcon } from '../../commons/default-types-values';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
  public readonly customerIcons$: Observable<CustomerIcon[]>;
  constructor(private iconApi: IconApi) {
    this.customerIcons$ = iconApi.get();
  }

}
