import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class ShipmentDurationApi {
  private readonly requestUrl: string = '/api/Brain';

  constructor(private http: HttpClient) { }

  getDuration(params: ShipmentParameters): Observable<Duration> {
    return this.http.post<Duration>(`${this.requestUrl}/Duration`,params);
  }
}

export interface ShipmentParameters {
  customerCode: string;
  plantCode: string;
  truckType: string;
  packages: Package[];
}

export interface Package {
  packageType: string;
  destinationCountry: string;
  deliveryMethod: string;
  quantity: number;
  action: number;
}

export interface Duration {
  expected: number;
  average: number;
}
