import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { CodeName } from "../../commons/generic-helper";

@Injectable()
export class AvgsManagementApi {
  private readonly requestUrl: string = '/api/AvgsManagement';

  constructor(private http: HttpClient) { }

  get(customerId: number): Observable<AvgElement[]> {
    return this.http.get<AvgElement[]>(`${this.requestUrl}/${customerId}`);
  }

  upsert(avg: AvgElement): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, avg);
  }

  delete(avg: AvgElement): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${avg.id}`);
  }
}

export interface AvgElement {
  id: number;
  customerId: number;
  plantId: number;
  plantCode: string;
  minutes: number;
  minutesToUpdate: number;
  rollingDays: number;
  frequency: number;
  magicNumber: number;
  overwrite: boolean;
  lastRun: Date;
}
