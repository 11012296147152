import { Component, OnInit } from '@angular/core';
import { CustomersApi, Customer } from './customers-api';
import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CodeName } from '../../commons/generic-helper';
import { IconApi } from '../icons/icon-api';
import { CustomerIcon } from '../../commons/default-types-values';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  customers = new BehaviorSubject<CodeName[]>(null);

  constructor(private api: CustomersApi, private modal: NgbModal, private router: Router) {
    this.api.getCustomerList().subscribe(x => { this.customers.next(x) });
  }

  ngOnInit(): void {
  }

  async delete(customer: CodeName) { }

  edit(customer: CodeName) {
    this.router.navigateByUrl('/settings/editCustomer/'+customer.code);
  }
}
