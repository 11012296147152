import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconApi } from "../../settings/icons/icon-api";

@Component({
  selector: 'app-delete-common-icon',
  templateUrl: './delete-common-icon.component.html',
  styleUrls: ['./delete-common-icon.component.scss']
})
export class DeleteCommonIconComponent implements OnInit {

  code: string = null;
  type: number = null;

  constructor(public readonly modal: NgbActiveModal, private iconApi: IconApi) {

  }

  ngOnInit() {

  }

  closeModal() {
    if (this.code && this.type != null) {
      this.iconApi.deleteCommonIcon(this.code, this.type).subscribe(x => {
        if (x > 0) {
          this.modal.close();
        } else {
          this.modal.dismiss();
        }
      })
    }
  }
}
