import { Component, OnInit, OnDestroy } from '@angular/core';
import { CodeName, GenericHelper } from '../../commons/generic-helper';
import { switchMap, tap, catchError, takeUntil, filter, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, combineLatest, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { PlantOptions, Rule, RulesManagementApi } from './rules-management-api';
import { DeleteRuleComponent } from './delete-rule/delete-rule.component';
import { UpsertRuleComponent } from './upsert-rule/upsert-rule.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrainyHelperChooserComponent } from '../brainy-helper-chooser/brainy-helper-chooser.component';
import { Validators, FormControl } from '@angular/forms';
import { CodeNameString, CommonApi, PlantTypes } from '../../commons/common-api';

@Component({
  selector: 'app-rules-management',
  templateUrl: './rules-management.component.html',
  styleUrls: ['./rules-management.component.scss']
})
export class RulesManagementComponent implements OnInit, OnDestroy {

  customerList: CodeName[];
  customerPlantsList: CodeName[];
  unsubscribe = new Subject<void>();
  customer = new FormControl(null, [Validators.required]);
  plant = new FormControl(null, [Validators.required]);
  public plantOptions: PlantTypes;
  rules = new BehaviorSubject<Rule[]>(null);

  constructor(private commonApi: CommonApi, private api: RulesManagementApi, private modal: NgbModal, private readonly parent: BrainyHelperChooserComponent) {
    this.commonApi.getCustomerList().subscribe(x => this.customerList = x);

    this.customer.valueChanges
      .pipe(
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        switchMap((customerId: number) =>
          this.commonApi.getCustomerPlantList(customerId))
      )
      .subscribe(x => {
        this.customerPlantsList = x;
        this.parent.hideProgressbar();
      });

    this.plant.valueChanges
      .pipe(
        filter(x => x),
        tap(x => this.parent.showProgressbar()),
        takeUntil(this.unsubscribe),
        map((x: string) => x != "*" ? x.split("-")[0] : x),
        switchMap((plantCode: string) =>
          forkJoin([this.api.get(this.customer.value, plantCode), this.commonApi.getPlantTypes(this.customer.value, plantCode)])
        )
      )
      .subscribe(x => {
        if (this.plant.value != "*") {
          let filtRules = x[0].filter(y => x[1].handlingUnits.some(z => z.code == y.packageType) || y.packageType == "*");
          this.rules.next(filtRules);
        }
        else {
          let t = x[0].filter(y => !x[1].handlingUnits.some(z => z.code == y.packageType && y.packageType != "*"))/*.forEach(y => { y.plantCode = "" });*/
          this.rules.next(x[0]);
        }
          
        this.plantOptions = x[1];
        this.parent.hideProgressbar();
      });
  }

  ngOnInit(): void {
  }

  async upsert(row: Rule = null) {
    let insertNew: boolean = row == null;

    let ref = await this.modal.open(UpsertRuleComponent);
    let toEdit: Rule = GenericHelper.initFromInstance(row);
    if (insertNew) {
      toEdit.id = -1;
      if (this.plant.value != "*") {
        toEdit.plantCode = this.plant.value.split("-")[0];
        toEdit.plantId = this.customerPlantsList.find(x => x.name == this.plant.value).code;
      }
      toEdit.customerId = parseInt(this.customer.value);
      toEdit.overwrite = false;
    }

    ref.componentInstance.rule = toEdit;
    ref.componentInstance.plantList = this.customerPlantsList;
    ref.componentInstance.title = insertNew ? "Add new rule" : "Edit rule";

    try {
      let result = await ref.result;
      this.api.upsert(toEdit)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            console.log("❌ " + x.error);
            this.parent.showError();
            return of([]);
          }),
          switchMap(x => this.api.get(this.customer.value, this.plant.value.split("-")[0]))
        )
        .subscribe(newList => { this.parent.showSuccess(); this.rules.next(newList); this.parent.hideProgressbar() })

    } catch{ }
  }

  async delete(row: Rule) {
    let ref = await this.modal.open(DeleteRuleComponent);
    try {
      let result = await ref.result;
      this.api.delete(row)
        .pipe(
          tap(() => this.parent.showProgressbar()),
          catchError((x: HttpErrorResponse) => {
            alert("❌ " + x.error);
            return of([]);
          }),
          switchMap(x => this.api.get(this.customer.value, this.plant.value.split("-")[0]))
        )
        .subscribe(newList => { this.rules.next(newList); this.parent.hideProgressbar() })

    } catch{ }
  } 

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public getName(codeNameArr: CodeNameString[], code: string) {
    return code === "*" ?
      "*" : codeNameArr.find(x => x.code.toLowerCase().trim() == code.toLowerCase().trim()) ?
        codeNameArr.find(x => x.code.toLowerCase().trim() == code.toLowerCase().trim()).name : code;
  }
}
