import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard<T extends IChangeUrl> implements CanDeactivate<T> {
    constructor(private readonly modal: NgbModal) {}

    //canDeactivate(component: T, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    async canDeactivate(component: T): Promise<boolean> {
        if (typeof component.changeUrl === 'function' && !component.changeUrl()) {
            try {
                let saveChanges: boolean = await this.modal.open(ConfirmModalComponent).result;
                if(saveChanges)
                    component.saveChanges();
                
                return true;
            } catch{
                return false;
            }
        }
        return true;
    }
}

export interface IChangeUrl {
    changeUrl: (() => boolean);
    saveChanges: (()=> void);
}
