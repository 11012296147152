import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { CodeName } from "./generic-helper";

@Injectable()
export class CommonApi {
  private readonly requestUrl: string = '/api/Common';

  constructor(private http: HttpClient) { }

  getCustomerList(): Observable<CodeName[]> {
    return this.http.get<CodeName[]>(`${this.requestUrl}/GetCustomerList`);
  }

  getCustomerPlantList(customerId: number): Observable<CodeName[]> {
    return this.http.get<CodeName[]>(`${this.requestUrl}/GetCustomerPlantList/${customerId}`);
  }
  getCustomerPlantCodes(customerId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.requestUrl}/GetCustomerPlantCodes/${customerId}`);
  }

  getPlantTypes(customerId: number, plantCode: string): Observable<PlantTypes> {
    return this.http.get<PlantTypes>(`${this.requestUrl}/GetPlantTypes/${customerId}/${plantCode}`);
  }
}

export interface CodeNameString {
  code: string;
  name: string;
}

export interface PlantTypes {
  truckTypes: CodeNameString[];
  handlingUnits: CodeNameString[];
  shipmentTypes: CodeNameString[];
  sourceDestinations: CodeNameString[];
}
